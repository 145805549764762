import { useNavigate } from "@remix-run/react";
import Selector from "antd-mobile/es/components/selector";
import { ReactNode } from "react";
import { useOnSplash } from "~/utils/data/useRecipeRouteData";
import clsx from "~/utils/shared/ClassesUtils";

//create Props
interface Props {
  children?: ReactNode;
  segments?: {
    name: string;
  }[];
  subNav?: ReactNode;
  tabs?:
  | {
    name: string;
    href: string;
    icon: ReactNode;
  }[]
  | null;
  left?: ReactNode;
  navbar: ReactNode;
  tabbar: ReactNode;
  navbarProps?: {
    large?: boolean;
  };
  leftClick?: () => void;
  right?: ReactNode;
  scrollEl?: any;
  tabBarProps?: {
    side?: "left" | "right";
    sideSize?: "1/4" | "1/3" | "1/2" | "2/3" | "3/4";
    mainSize?: "1/4" | "1/3" | "1/2" | "2/3" | "3/4";
    breakpointClass?: "sm" | "md" | "lg" | "xl" | "2xl";
    sider?: boolean;
  };
  relativeContent?: boolean;
  shadow?: boolean;
  layoutClassName?: string;
  headerClass?: string;
}

export default function AppLayout({
  segments,
  subNav,
  children,

  navbar,

  tabbar,
  layoutClassName,
  shadow = true,
  headerClass,
}: Props) {
  const navigate = useNavigate();
  const onSplash = useOnSplash();

  let subNavBar = subNav ? subNav : null;
  if (segments && segments.length > 0) {
    // subNavBar = (
    //   <Segmented strong>
    //     {segments.map((segment) => (
    //       <SegmentedButton small strong active={segment.active}>
    //         {segment.name}
    //       </SegmentedButton>
    //     ))}
    //   </Segmented>
    // );

    subNavBar = (
      <Selector
        columns={segments.length}
        options={segments.map((segment) => ({
          label: segment.name,
          value: segment.name,
        }))}
        value={segments.reduce((ac, seg) => {
          if (seg.active) {
            return [...ac, seg.name];
          }
        }, [])}
        multiple={true}
      />
    );
  }

  return (
    <>
      {/* <SafeArea position="top" /> */}
      <div className={clsx("app-layout flex flex-1 flex-col", layoutClassName)}>
        {/* <div>
        <SafeArea position="top" />
      </div> */}
        {navbar && (
          <header
            className={clsx("sticky top-0 z-20 flex-none bg-white", shadow && !onSplash && "shadow", "pt-safe", headerClass)}
            style={{ "--tw-shadow": "0 2px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1)" }}
          >
            {navbar}
          </header>
        )}
        {/* <div className="flex flex-1 flex-row overflow-y-hidden">{children}</div> */}
        {children}
        {tabbar && (
          <footer
            className={"sticky bottom-0 z-0 flex flex-none bg-white shadow pb-safe"}
            style={{ "--tw-shadow": "0 2px 3px 0 rgb(0 0 0 / .1), 0 -3px 2px -1px rgb(0 0 0 / .1)" }}
          >
            {tabbar}
          </footer>
        )}
        {/* <div>
        <SafeArea position="bottom" />
      </div> */}
      </div>
      {/* <SafeArea position="bottom" /> */}
    </>
  );

  // return (
  //   <>
  //     <Navbar
  //       scrollEl={scrollEl}
  //       {...navbarProps}
  //       title={
  //         <Transition
  //           appear={true}
  //           show={true}
  //           enter="transition-opacity duration-300"
  //           enterFrom="opacity-0"
  //           enterTo="opacity-100"
  //           leave="transition-opacity duration-300"
  //           leaveFrom="opacity-100"
  //           leaveTo="opacity-0"
  //           className="truncate"
  //         >
  //           {title}{" "}
  //         </Transition>
  //       }
  //       subtitle={
  //         <Transition
  //           appear={true}
  //           show={true}
  //           enter="transition-opacity duration-300"
  //           enterFrom="opacity-0"
  //           enterTo="opacity-100"
  //           leave="transition-opacity duration-300"
  //           leaveFrom="opacity-100"
  //           leaveTo="opacity-0"
  //           className="truncate"
  //         >
  //           {subtitle}
  //         </Transition>
  //       }
  //       right={right}
  //       subnavbar={subNavBar}
  //       left={
  //         <Transition
  //           appear={true}
  //           show={true}
  //           enter="transition-opacity duration-300"
  //           enterFrom="opacity-0"
  //           enterTo="opacity-100"
  //           className="h-full w-14"
  //           leave="transition-opacity duration-300"
  //           leaveFrom="opacity-100"
  //           leaveTo="opacity-0"
  //           onClick={leftClick}
  //         >
  //           {left}
  //         </Transition>
  //       }
  //     />
  //     <Transition
  //       appear={true}
  //       show={true}
  //       enter={clsx("transition-opacity duration-300")}
  //       entered={clsx(tabs && "pb-20")}
  //       enterFrom="opacity-0"
  //       enterTo="opacity-100"
  //       leave="transition-opacity duration-300"
  //       leaveFrom="opacity-100"
  //       leaveTo="opacity-0"
  //       className={clsx("content", "flex flex-1 flex-col", tabs && "pb-20")}
  //     >
  //       {children}
  //     </Transition>
  //     {tabs ? (
  //       <Tabbar
  //         labels={true}
  //         icons={true}
  //         className={clsx(
  //           "fixed bottom-0",
  //           tabBarProps?.sider
  //             ? `${tabBarProps.breakpointClass}:${tabBarProps.side}-0 ${tabBarProps.breakpointClass}:w-${tabBarProps.sideSize}`
  //             : tabBarProps
  //             ? `${tabBarProps?.breakpointClass}:${tabBarProps?.side}-${tabBarProps?.sideSize} ${tabBarProps.breakpointClass}:w-${tabBarProps.mainSize}`
  //             : ""
  //         )}
  //         translucent
  //       >
  //         {tabs.map((tab) => (
  //           <TabbarLink onClick={tab.onClick ? tab.onClick : () => navigate(tab.href)} key={tab.name} label={tab.name} icon={tab.icon} />
  //         ))}
  //       </Tabbar>
  //     ) : null}
  //   </>
  // );
}
